import { Link } from "gatsby"
import React from "react"

const NotFound = () => {
  return (
    <div className="text-center mt-56 p-2">
      <h5 className="text-primary text-3xl mb-16">
        Sorry, the page is not found :(
      </h5>
      <Link
        to="/"
        className=" text-white font-medium text-xl border-2 px-5 py-3 border-primary"
      >
        Back Home
      </Link>
      <h5 className="text-primary font-semibold text-8xl mt-20">404</h5>
    </div>
  )
}

export default NotFound
